import React, {useState} from 'react';
import './App.css';
import { LanguagePage, GreetingPage, CityPage, DocumentPage, AddressPage, Video, DocumentBackPage, CongratsPage, RoadMapPage } from './components'
import * as icons from './icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { saveData } from './api'
import Spinner from 'react-spinkit'

const icon = require('./assets/Velai.png')

library.add(icons)

const colorCode = '#FF4C5A'

const App = (props) => {

  console.log(props)
  const [isLoading, setIsLoading] = useState(false)

  const [progress, setProgress] = useState(-1)

  // 0
  const [phoneNumber, setPhoneNumber] = useState('')

  //1
  const [language, setLanguage] = useState('english')

  //2
  const [city, setCity] = useState('singapore')

  //3
  const [name, setName] = useState('')
  const [DLNumber, setDLNumber] = useState('')
  const [localDLFileFront, setLocalDLFileFront] = useState(null)
  const [localDLFileFrontLink, setLocalDLFileFrontLink] = useState(null)

  //4
  const [localDLFileBack, setLocalDLFileBack] = useState(null)
  const [localDLFileBackLink, setLocalDLFileBackLink] = useState(null)

  //5
  const [address, setAddress] = useState(['', '',''])
  const [localAddFileFront, setLocalAddFileFront] = useState(null)
  const [localAddFileFrontLink, setLocalAddFileFrontLink] = useState(null)

  // //6
  // const [localAddFileBack, setLocalAddFileBack] = useState(null)
  // const [localAddFileBackLink, setLocalAddFileBackLink] = useState(null)

  //6
  const [watchVid1, setWatchVid1] = useState(false)

  const handleSubmitProceed = async () => {
    try {
      setIsLoading(true)
      
      await saveData({phoneNumber, name, city, DLNumber, address, localAddFileFront, localDlFileBack: localDLFileBack, localDlFileFront: localDLFileFront})
      setProgress(7)
      URL.revokeObjectURL(localAddFileFrontLink)
      URL.revokeObjectURL(localDLFileBackLink)
      URL.revokeObjectURL(localDLFileFrontLink)
      setIsLoading(false)
    }
    catch (e){
      console.error(e.stack)
    }
    
  }

  const renderProgress = () => {
    switch(progress){
      case -1:
        return <RoadMapPage proceed={() => {setProgress(0)}}/>
      case 0:
        return <GreetingPage proceed={() => {setProgress(1)}} setPhoneNumber={setPhoneNumber} defaultValue={phoneNumber} back={() => setProgress(-1)}/>
      case 1:
        return <LanguagePage proceed={() => setProgress(2)} back={() => setProgress(0)} setLanguage={setLanguage} defaultValue={language}/>
      case 2:
        return <CityPage language={language} proceed={() => setProgress(3)} back={() => setProgress(1)} setCity={setCity} defaultValue={city} />
      case 3: 
        return <DocumentPage proceed={() => setProgress(4)} back={() => setProgress(2)}
          setName={setName} 
          setDL={setDLNumber} 
          defaultDLNumber={DLNumber} 
          defaultName={name}
          setFile={setLocalDLFileFront}
          setFileLink={setLocalDLFileFrontLink}
          defaultFile={localDLFileFront}
          defaultFileLink={localDLFileFrontLink}
          language={language}/>
      case 4: 
        return <DocumentBackPage proceed={() => setProgress(5)} back={() => setProgress(3)}
          setFile={setLocalDLFileBack}
          setFileLink={setLocalDLFileBackLink}
          defaultFile={localDLFileBack}
          defaultFileLink={localDLFileBackLink}
          isBack
          language={language}/>
      case 5:
        return <AddressPage proceed={() => setProgress(6)} back={() => setProgress(4)}
          setAddress={setAddress}
          defaultAddress={address}
          setFile={setLocalAddFileFront}
          setFileLink={setLocalAddFileFrontLink}
          defaultFile={localAddFileFront}
          defaultFileLink={localAddFileFrontLink}
          language={language} />
      // case 6:
      //   return <AddressBackPage proceed={() => setProgress(7)} back={() => setProgress(5)}
      //     setFile={setLocalAddFileBack}
      //     setFileLink={setLocalAddFileBackLink}
      //     defaultFile={localAddFileBack}
      //     defaultFileLink={localAddFileBackLink} />
      case 6 :
        // return <Video videoId="zqAVLYAJnVU" back={() => setProgress(5)} proceed={handleSubmitProceed} defaultWatch={watchVid1} setWatchVid={setWatchVid1} language={language}/>
        return <Video videoId="s3rPhBIv738" back={() => setProgress(5)} proceed={handleSubmitProceed} defaultWatch={watchVid1} setWatchVid={setWatchVid1} language={language}/>
      default:
        return <CongratsPage language={language}/>    
    }
  }

  // console.log(name, DLNumber)
  // console.log(localDLFileFront)
  // console.log(localDLFileBack)
  // console.log(address)
  // console.log(localAddFileFront)
  // console.log(localAddFileBack)

  return <>
    <AppBar position="static">
      <Toolbar style={{padding: "10px 0"}}>
        <img width={50} src={icon} alt="logo"/>
        {/* <Typography variant="h5" style={{color: "000"}}>
        KNOW
      </Typography> */}
      </Toolbar>
    </AppBar>
    {
      isLoading ? <div style={{display:"flex", height: "calc(100vh - 100px)", alignItems: "center", flexDirection: "row", justifyContent: "center"}}>
          <Spinner name="ball-spin-fade-loader" color={colorCode}/> 
      </div> :<div className="d-flex app-container container">
        {renderProgress()}
      </div>
    }
    
  </>
}

export default App