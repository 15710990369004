import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'
import moment from 'moment'
import { orgName } from './constants'

const fbConfig = {
    apiKey: "AIzaSyAcyioEMRPTHKhesOcWLcw0qnrHlimL3AE",
    authDomain: "know-clients-json.firebaseapp.com",
    databaseURL: "https://know-clients-json.firebaseio.com",
    projectId: "know-clients-json",
    storageBucket: "know-clients-json.appspot.com",
    messagingSenderId: "876269676266",
    appId: "1:876269676266:web:41f42e7ce7e335c3"
};

const app = firebase.initializeApp(fbConfig)

const firestore = firebase.firestore()
const storage = firebase.storage(app)

export const saveData = async ({ phoneNumber, name, city, DLNumber, localDlFileFront, localDlFileBack, address, localAddFileFront, localAddFileBack }) => {

    const [DLDocFront, addressDocFront, DLDocBack] = await Promise.all([
        await uploadPic(localDlFileFront, "driving_licence", phoneNumber),
        await uploadPic(localAddFileFront, "proof_adress", phoneNumber),
        await uploadPic(localDlFileBack, "driving_licence", phoneNumber)
    ])
    // const addressDocBack = await uploadPic(localAddFileBack, "proof_adress", phoneNumber)

    const data = {
        phone_number: phoneNumber,
        name,
        work_city: city,
        driving_licence: DLNumber,
        driving_licence_doc_front: DLDocFront,
        driving_licence_doc_back: DLDocBack,
        platform: 'web',
        address,
        address_doc_front: addressDocFront,
        // address_doc_back: addressDocBack,
        token: '9u1xt7KMkotLXLAWk858'
    }

    console.log(data)

    await firestore.collection('orgs').doc(orgName).collection('onboardRequests').add(data).catch(err => console.error(err))
}

const uploadPic = async (file, type = "driving_licence", phoneNumber) => {
    const ref = storage.ref(`know_web/${orgName}/${type}/${phoneNumber}/${phoneNumber}_${type}_${+moment()}_${file.name}`)

    const url = await ref.put(file).then(async snapshot => snapshot.ref.getDownloadURL())

    return url
}