import React from 'react'
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyle = makeStyles(theme => ({
    textField: {
        display: 'flex',
        marginTop: 10,
    },
    text : {
        color: "#666f7b",
        marginBottom: 20
    },
    button: {
        display: 'flex',
        // marginTop: 10,
        width: '100%',
        height: '100%'
    },
    nextSpan: {
        width: '100%',
        display: 'block'
    },
}))

export const RoadMapPage = ({proceed, setPhoneNumber, defaultValue}) => {
    const classes = useStyle()

    return <>
                <div className="info-div">
                    <h3>Welcome to Velai</h3>
                    <div className={classes.text}>Your rider application</div>
                    <div className="roadmap-item">
                        <div className="roadmap-details">
                            <h5>Phone number</h5>
                        </div>
                        <span><FontAwesomeIcon icon="lock"/> </span>
                    </div>
                    <div className="roadmap-item">
                        <div className="roadmap-details">
                            <h5>Work city</h5>
                        </div>
                        <span><FontAwesomeIcon icon="lock"/> </span>
                    </div>
                    <div className="roadmap-item">
                        <div className="roadmap-details">
                            <h5>Driver's Licence Document</h5>
                        </div>
                        <span><FontAwesomeIcon icon="lock"/> </span>
                    </div>
                    <div className="roadmap-item">
                        <div className="roadmap-details">
                            <h5>Proof Of Address</h5>
                        </div>
                        <span><FontAwesomeIcon icon="lock"/> </span>
                    </div>
                    <div className="roadmap-item">
                        <div className="roadmap-details">
                            <h5>Introduction Video</h5>
                        </div>
                        <span><FontAwesomeIcon icon="lock"/> </span>
                    </div>
                </div>
                
                
                {/* <span style={{flexGrow: 1}}></span> */}
                <div className="button-next-back">
                    <div className="d-flex" style={{flexDirection: 'row-reverse'}}>
                        <span className={classes.nextSpan}><Button className={classes.button} type="submit" onClick={proceed} variant="contained" color="primary">
                            Get Started
                        </Button></span>
                    </div>
                </div> 
                
            </>
}